<template>
    <v-row style="align-items: center;">

        <v-col cols="6">

            <v-progress-circular v-show="daysUsed >= 0" :value="value" size="120" width="5"
                :color="value < 100 ? 'primary' : 'success'" :indeterminate="daysUsed < 0" rotate="270"
                class="rounded-lg">
                <template>
                    <v-slide-x-transition>

                        <v-row v-show="daysUsed > -1">
                            <v-col>

                                <h1 class="text-h2 " style="font-weight:bold">{{ daysUsed }}<span class="text-h5">
                                        / {{ maxDays }}
                                    </span>
                                </h1>
                                <h5 style="font-size:0.6rem">
                                    progreso semanal
                                </h5>
                            </v-col>
                        </v-row>


                    </v-slide-x-transition>
                </template>
            </v-progress-circular>
            <v-skeleton-loader v-show="daysUsed == -1" type="image" width="100%" height="120"></v-skeleton-loader>

        </v-col>

        <v-col cols="6">

            <h1 v-show="daysUsed >= 0">
                <v-slide-x-transition>
                    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 24 24"
                        class="duoicon duoicon-fire" v-bind:class="{ 'trained': daysUsed > 0 }">
                        <path id="firePath1" fill="currentColor"
                            d="m12 12.9-2.13 2.09c-.56.56-.87 1.29-.87 2.07C9 18.68 10.35 20 12 20s3-1.32 3-2.94c0-.78-.31-1.52-.87-2.07L12 12.9z"
                            class="duoicon-primary-layer"></path>
                        <path id="firePath2" fill="currentColor"
                            d="m16 6-.44.55C14.38 8.02 12 7.19 12 5.3V2S4 6 4 13c0 2.92 1.56 5.47 3.89 6.86-.56-.79-.89-1.76-.89-2.8 0-1.32.52-2.56 1.47-3.5L12 10.1l3.53 3.47c.95.93 1.47 2.17 1.47 3.5 0 1.02-.31 1.96-.85 2.75 1.89-1.15 3.29-3.06 3.71-5.3.66-3.55-1.07-6.9-3.86-8.52z"
                            class="duoicon-secondary-layer" opacity=".3"></path>
                    </svg>
                </v-slide-x-transition>
            </h1>
            <v-skeleton-loader v-show="daysUsed == -1" type="image" width="100%" height="120"></v-skeleton-loader>

        </v-col>

        <v-col cols="12">
            <!-- <v-stepper class="pa-0" elevation="0" v-show="daysUsed > -1">

                <v-stepper-header class="pa-0">

                    <template v-for="i in maxDays">
                        <v-stepper-step :complete="i <= daysUsed" :step="i" complete-icon="mdi-fire" :key="i + 'dem'">
                            <v-icon>mdi-fire</v-icon>
                        </v-stepper-step>

                        <v-divider v-if="i < maxDays"
                            v-bind:class="{ 'complete': i < daysUsed, 'incomplete': i > daysUsed }"></v-divider>
                    </template>

                </v-stepper-header>
            </v-stepper> -->
            <v-skeleton-loader v-show="daysUsed == -1" type="image" width="100%" height="80"></v-skeleton-loader>
            <v-slide-x-transition>
                <v-alert prominent dense type="info" elevation="0" v-show="daysUsed > -1 && !workedOutToday" text
                    class="rounded-lg mb-0 text-left">
                    Completa tus entrenamientos diarios para avanzar en tu progreso semanal.
                    <br/>
                    <v-btn  text  color="yellow darken-4" small class="mt-2"  @click="$router.push('/clases')">
                        Reserva tu entrenamiento
                    </v-btn>
                </v-alert>
            </v-slide-x-transition>

            <v-slide-x-transition>

                <v-alert prominent dense type="success" elevation="0" v-show="daysUsed === maxDays" class="text-left  mb-0" text>
                    ¡Felicidades! Has completado tu progreso semanal. Recuerda reservar tu horario para la próxima
                    semana.
                </v-alert>
            </v-slide-x-transition>

            <v-slide-x-transition v-if="daysUsed < maxDays">

                <v-alert prominent dense text type="success" elevation="0" v-show="workedOutToday && daysUsed < maxDays"
                    class="text-left  mb-0">
                    ¡Excelente! Has completado tu entrenamiento de hoy. Recuerda reservar tu horario para mañana.
                </v-alert>
            </v-slide-x-transition>
        </v-col>
    </v-row>
</template>

<script>

export default {
    props: {

        daysUsed: {
            type: Number,
            required: false,
            default: null
        },
        maxDays: {
            type: Number,
            required: false,
            default: 7,

        },
        workedOutToday: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    computed: {
        value() {
            return this.daysUsed * 100 / this.maxDays;
        },
    },
};
</script>

<style scoped>
.text-h5 {
    font-weight: bolder;
}

.steps {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.steps .v-icon {
    margin: 0 2px;
}


.complete {
    border-color: var(--v-primary-base) !important;
}

.trained {
    color: #fe8200;
}
</style>
